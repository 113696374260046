var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c("page-title", { attrs: { "show-back-btn": "" } }),
      _c(
        "div",
        { staticClass: "partition-area" },
        [
          _c("ics-company-info", {
            attrs: {
              "page-title": "核心企业信息",
              "info-detail": _vm.coreDetail,
              "loading-detail": _vm.loading
            },
            on: { submit: _vm.submitForms }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }