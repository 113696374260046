<template>
  <el-form ref="appForm" v-loading="loadingDetail.detail" :model="appForm" :rules="rules" label-width="170px" :label-suffix="constants.labelSuffix">
    <ics-page-inner :title="pageTitle">
      <el-col v-if="!editView()" :span="12">
        <el-form-item label="企业名称">
          <p>{{ utils.isEffectiveCommon(appForm.companyName) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="!editView()" :span="12">
        <el-form-item label="统一社会信用代码">
          <p>{{ utils.isEffectiveCommon(appForm.socialNo) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="editView()" :span="12">
        <el-form-item prop="companyName" label="企业名称">
          <ics-item-inner :prop="appForm.companyName" :format="utils.isEffectiveCommon">
            <el-input v-model="appForm.companyName" placeholder="请输入企业名称" />
          </ics-item-inner>
        </el-form-item>
      </el-col>
      <el-col v-if="editView()" :span="12">
        <el-form-item prop="socialNo" label="统一社会信用代码">
          <ics-item-inner :prop="appForm.socialNo" :format="utils.isEffectiveCommon">
            <el-input v-model="appForm.socialNo" placeholder="请输入统一社会信用代码" />
          </ics-item-inner>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="legalPerson" label="法定代表人姓名">
          <ics-item-inner :prop="appForm.legalPerson" :format="utils.isEffectiveCommon">
            <el-input v-model="appForm.legalPerson" placeholder="请输入法定代表人姓名" />
          </ics-item-inner>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="legalIdentity" label="法定代表人身份证号码">
          <ics-item-inner :prop="appForm.legalIdentity" :format="utils.isEffectiveCommon">
            <el-input v-model="appForm.legalIdentity" placeholder="请输入法定代表人身份证号码" />
          </ics-item-inner>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="注册资本(元)">
          <ics-item-inner :prop="appForm.regAmount" :format="(val)=>utils.moneyFormat(val,2)">
            <el-input v-model="appForm.regAmount" type="number" placeholder="请输入注册资本" />
          </ics-item-inner>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="固定电话" prop="tel">
          <ics-item-inner :prop="appForm.tel" :format="utils.isEffectiveCommon">
            <el-input v-model="appForm.tel" placeholder="请输入固定电话" />
          </ics-item-inner>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="电子邮箱">
          <ics-item-inner :prop="appForm.email" :format="utils.isEffectiveCommon">
            <el-input v-model="appForm.email" placeholder="请输入电子邮箱" />
          </ics-item-inner>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="传真">
          <ics-item-inner :prop="appForm.fax" :format="utils.isEffectiveCommon">
            <el-input v-model="appForm.fax" placeholder="请输入传真" />
          </ics-item-inner>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="contactName" label="联系人姓名">
          <ics-item-inner :prop="appForm.contactName" :format="utils.isEffectiveCommon">
            <el-input v-model="appForm.contactName" placeholder="请输入联系人姓名" />
          </ics-item-inner>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="contactPhone" label="联系人电话">
          <ics-item-inner :prop="appForm.contactPhone" :format="utils.isEffectiveCommon">
            <el-input v-model="appForm.contactPhone" placeholder="请输入联系人电话" />
          </ics-item-inner>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="公司网站">
          <ics-item-inner :prop="appForm.net" :format="utils.isEffectiveCommon">
            <el-input v-model="appForm.net" placeholder="请输入公司网站" />
          </ics-item-inner>
        </el-form-item>
      </el-col>
      <el-col v-if="viewEdit()" :span="12">
        <el-form-item prop="usEnterpriseAddress" label="注册地址">
          <ics-item-inner :prop="appForm.usEnterpriseAddress" :format="utils.isEffectiveCommon">
            <address-picker v-model="appForm.usEnterpriseAddress" :is-show-address-info="isShowAddressInfo" />
          </ics-item-inner>
        </el-form-item>
      </el-col>
      <el-col v-if="!viewEdit()" :span="12">
        <el-form-item label="注册地址">
          <address-picker-detail v-model="appForm.usEnterpriseAddress" />
        </el-form-item>
      </el-col>
      <el-col v-if="viewEdit()" :span="12">
        <el-form-item prop="dsEnterpriseAddress" label="办公地址">
          <ics-item-inner :prop="appForm.dsEnterpriseAddress" :format="utils.isEffectiveCommon">
            <address-picker v-model="appForm.dsEnterpriseAddress" :is-show-address-info="isShowAddressInfo" />
          </ics-item-inner>
        </el-form-item>
      </el-col>
      <el-col v-if="!viewEdit()" :span="12">
        <el-form-item label="办公地址">
          <address-picker-detail v-model="appForm.dsEnterpriseAddress" />
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="经营范围">
          <ics-item-inner :prop="appForm.scope" :format="utils.isEffectiveCommon">
            <el-input v-model="appForm.scope" placeholder="请输入经营范围(限300字)" :maxlength="300" type="textarea" />
          </ics-item-inner>
        </el-form-item>
      </el-col>
    </ics-page-inner>
    <ics-page-inner title="配置管理">
      <el-col :span="12">
        <el-form-item prop="authenticRightFlag" label="是否进行应收账款确权">
          <ics-item-inner :prop="appForm.authenticRightFlag" :format="(val)=>utils.statusFormat(Number(val), 'authenticRightFlag')">
            <el-radio-group v-model="appForm.authenticRightFlag">
              <el-radio v-for="item in constants.authenticRightFlag" :key="item.value" :label="item.value">
                {{ item.label }}
              </el-radio>
            </el-radio-group>
          </ics-item-inner>
        </el-form-item>
      </el-col>
    </ics-page-inner>
    <el-row v-if="viewEdit()" :gutter="80">
      <el-col :span="24" style="display: flex;justify-content:center;margin-bottom: 50px;padding-top: 30px;">
        <el-button @click="$router.back()">
          返 回
        </el-button>
        <debounce-button type="primary" style="margin-right: 10px;" :loading="loadingDetail.submit" @click="submitForms">
          提 交
        </debounce-button>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import AddressPicker from '@/components/template/address-picker'
import AddressPickerDetail from '@/components/template/address-picker-detail'
import axios from 'axios'
export default {
  name: 'IcsCompanyInner',
  components: { AddressPicker, AddressPickerDetail},
  mixins: [routeEnterMixin],
  props: {
    loadingDetail: {
      type: Object,
      default () {
        return {}
      }
    },
    companyType: {
      type: String,
      default: ''
    },
    pageTitle: {
      type: String,
      default: ''
    },
    infoDetail: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    const validatePhone  = (rule, value, callback) => {
      if (/^\d{3}-\d{8}|\d{4}-\d{7,8}$/.test(value) || /^1[3|4|5|7|8|9][0-9]\d{8}$/.test(value)) {
        callback()
      } else {
        callback(new Error('请输入正确的手机号或固定电话'))
      }
    }
    return {
      isShowAddressInfo: false,
      appForm: {
        companyName: '',
        socialNo: '',
        legalPerson: '',
        legalIdentity: '',
        regAmount: '',
        tel: '',
        email: '',
        fax: '',
        contactName: '',
        contactPhone: '',
        net: '',
        authenticRightFlag: 1,
        usEnterpriseAddress: [],
        dsEnterpriseAddress: [],
        scope: '',
        cityList: []
      },
      loadingCity: {
        tab: false
      },
      cityList: [],
      capCityList: [],
      rules: {
        tel: [
          { required: true, message: '固定电话', trigger: 'change' },
          { validator: validatePhone, trigger: 'blur' }
        ],
        companyName: this.changeRules({ name: '企业名称', required: true }),
        socialNo: this.changeRules({ name: '统一社会信用代码', required: true }),
        legalPerson: this.changeRules({ name: '法定代表人姓名', required: true }),
        legalIdentity: this.changeRules({ name: '法定代表人身份证号码', required: true }),
        contactName: this.changeRules({ name: '联系人姓名', required: true }),
        contactPhone: [
          { required: true, message: '联系人电话', trigger: 'change' },
          { validator: validatePhone, trigger: 'blur' }
        ],
        usEnterpriseAddress: [
          this.rulesToolkit.rules.required({ name: '注册地址', trigger: 'change' })
        ],
        dsEnterpriseAddress: [
          this.rulesToolkit.rules.required({ name: '办公地址', trigger: 'change' })
        ],
        authenticRightFlag: [
          this.rulesToolkit.rules.required({ name: '应收账款确权', trigger: 'change' })
        ],
      }
    }
  },
  watch: {
    infoDetail: 'initData'
  },
  created () {
    if (this.viewEdit()) {
    }
  },
  methods: {
    initData (value) {
      console.log(value)
      this.appForm = Object.assign(this.appForm, this.utils.filterTrimValue(value))
    },
    editView () {
      if (this.$route.params.editMode === 'edit') {
        return false
      } else {
        return true
      }
    },
    submitForms () {
      const cityList = []
      if (this.companyType === 'capital') {
        const selectCity = this.$refs.city.$refs.areaTree.getCheckedNodes()
        selectCity.forEach((val, index, arr) => {
          if (val.code) {
            const city = {}
            city.areaCode = val.code
            city.areaName = val.name
            if (val.parentCode) {
              city.parentCode = val.parentCode
            }
            cityList.push(city)
          }
        })
        if (cityList.length <= 0) {
          this.$message.warning('请选择业务覆盖城市')
          return false
        }
        this.$refs.appForm.validate().then(() => {
          this.$emit('submit', this.appForm, cityList)
        })
      } else {
        this.$refs.appForm.validate().then(() => {
          this.$emit('submit', this.appForm)
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
